
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import VenueLabelTable from '@/components/venueLabel/VenueLabelTable.vue';
import { Venue } from '@/interfaces/models/Venue';
import { Permission } from '@/enums/Permission';

const venue = namespace('venue');
const venueLabel = namespace('venueLabel');

@Component({
  components: { VenueLabelTable, VEmptyState, VWrapper },
})
export default class VenueLabel extends Vue {
  @venue.Action('fetch') public getVenues!: any;
  @venue.State('items') public venues!: Venue[];

  @venueLabel.State((state) => state.pagination.total) public total!: number;
  @venueLabel.State('items') public venueLabels!: VenueLabel[];
  @venueLabel.Action('fetch') public getVenueLabels!: any;
  @venueLabel.Action('delete') public deleteVenueLabel!: any;

  public async mounted() {
    this.$startLoading('venueLabel');
    await this.getVenues();
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels({ page: 1 });
    }
    this.$stopLoading('venueLabel');
  }
}

export default [
  {
    text: 'venueLabel.table.name',
    value: 'name',
  },
  {
    text: 'venueLabel.table.slug',
    value: 'slug',
  },
  {
    text: 'venueLabel.table.venues',
    value: 'venues',
    type: 'array',
  },
];


import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Venue } from '@/interfaces/models/Venue';

@Component({
  components: { VTable },
})
export default class VenueLabelTable extends Vue {
  @Prop({ type: Number, required: true }) public total!: number;
  @Prop({ type: Array, required: true }) public venueItems!: Venue[];
  @Prop({ type: Array, required: true }) public items!: VenueLabel[];
  public headers: ColumnHeader[] = headers;

  public edit(item: VenueLabel) {
    this.$router.push({ name: 'venueLabel.edit', params: { id: item._id } });
  }

  get preparedItems() {
    return this.items.map((item: VenueLabel) => {
      const venueNames = this.venueItems.filter((venue: Venue) => item.venues && item.venues.includes(venue._id));

      return {
        ...item,
        venues: venueNames.map((venue: Venue) => venue.name),
      };
    });
  }
}
